import { render, staticRenderFns } from "./item-list-entry.vue?vue&type=template&id=0e65ef68&scoped=true"
import script from "./item-list-entry.vue?vue&type=script&lang=ts&setup=true"
export * from "./item-list-entry.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./item-list-entry.vue?vue&type=style&index=0&id=0e65ef68&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e65ef68",
  null
  
)

export default component.exports